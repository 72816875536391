import Train from "../../../components/sections/Train";
import ActionBar from "../../../components/sections/ActionBar";
import Yard from "../../../components/sections/Yard";
import { notification, Spin } from "antd";
import { useCallback, useState } from "react";
import jwtAxios from "../../../auth/jwt-api";
import { AreaNames, Container3D, MatrixContainers, MatrixGrid } from "../../../shared/types/container";
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";
import { useQuery, useQueryClient } from "react-query";
import { DandActionsEnum, extractDandId } from "../../../helpers/draganddrop";



function MainPage() {
  const queryClient = useQueryClient()

  const { data: response, isLoading } = useQuery('container-matrix', () => {
    return jwtAxios.get<MatrixContainers>("/master/container-matrix")
  })
  const [search, setSearch] = useState('')
  const [dandAction, setDandAction] = useState(DandActionsEnum.RELOCATING)
  const [currentGrid, setCurrentGrid] = useState<MatrixGrid | null>(null)

  const setCurrentGridCallback = useCallback((matrixGrid: MatrixGrid) => {
    setCurrentGrid(matrixGrid)
  }, [])

  const setCurrentGridByContainer = (container: Container3D) => {
    if (container.position) {
      const gridName = container.position.grid_name
      const [areaName, x, y] = gridName.split('.') as [AreaNames, number, number]
      const grid = response?.data[areaName][+y - 1][+x - 1] ?? null;
      setCurrentGrid(grid)
      queryClient.refetchQueries(['grid', gridName])
    }
  }


  const onDragEnd: OnDragEndResponder = (respond) => {
    const { source, destination, draggableId } = respond

    if (destination) {
      const [desAction, desPrefix, desPayload] = extractDandId(destination.droppableId)
      const [srcAction, srcPrefix, srcPayload] = extractDandId(source.droppableId)
      const [dgAction, dgPrefix, dgPayload] = extractDandId(draggableId)

      if (desAction == DandActionsEnum.RELOCATING) { /////////////////////////  RELOCATING
        jwtAxios.post(`/master/container-relocating`, {
          grid_name: srcPayload, // currentGridName,
          new_grid_name: desPayload, // targetGridName,
          container_number: dgPayload, //containerNumber
        })
          .then(res => {
            notification.success({ message: 'Moved successfully' })
            queryClient.refetchQueries('container-matrix')
            queryClient.refetchQueries(['grid', currentGrid?.name])
            queryClient.refetchQueries(['container-actions', search])
          })
          .catch(err => {
            notification.error({ message: err.response.data.message ?? 'Error occured on moving' })
          })
      } else if (desAction == DandActionsEnum.RETURNING) { /////////////////////////  RETURNING

        jwtAxios.post(`/master/container-returning`, {
          grid_name: srcPayload, // currentGridName,
          container_number: dgPayload // containerId
        })
          .then(res => {
            notification.success({ message: 'Returned succesfully' })
            queryClient.refetchQueries('container-matrix')
            queryClient.refetchQueries('containers-for-setbacking')
            queryClient.refetchQueries(['grid', currentGrid?.name])
            queryClient.refetchQueries(['container-actions', search])
          })
          .catch(err => {
            notification.error({ message: err.response.data.message ?? 'Error occured on returning' })
          })


      } else if (desAction == DandActionsEnum.UNLOADING) { /////////////////////////  UNLOADING

        jwtAxios.post(`/master/container-unloading`, {
          grid_name: srcPayload, // currentGridName,
          container_number: dgPayload // containerId
        })
          .then(res => {
            notification.success({ message: 'Unloaded succesfully' })
            queryClient.refetchQueries('container-matrix')
            queryClient.refetchQueries('containers-for-setbacking')
            queryClient.refetchQueries(['grid', currentGrid?.name])
            queryClient.refetchQueries(['container-actions', search])
          })
          .catch(err => {
            notification.error({ message: err.response.data.message ?? 'Error occured on unloading' })
          })
      }
    }
  }


  return (
    <>
      {/* <div>{JSON.stringify(currentGrid)}</div> */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Spin spinning={isLoading}>
          {response && (<>
            <Yard key={1}
              states={{ section: response.data.A, name: "A", dandAction, currentGrid }}
              actions={{ setCurrentGrid: setCurrentGridCallback }} />
            <Train
              states={{ section: response.data.B, name: "B", dandAction, currentGrid }}
              actions={{ setCurrentGrid: setCurrentGridCallback }} />
            <Yard key={2}
              states={{ section: response.data.C, name: "C", dandAction, currentGrid }}
              actions={{ setCurrentGrid: setCurrentGridCallback }} />
          </>)}
        </Spin>
        <ActionBar
          states={{ currentGrid, dandAction, search }}
          actions={{ setSearch, setCurrentGridByContainer, setCurrentGrid: setCurrentGridCallback }} />
      </DragDropContext>
    </>
  );
}

export default MainPage;
