import React from "react";
import styles from "./c3d.module.scss";
import clsx from "clsx";
import C3D from "./C3D";
import { Droppable } from "react-beautiful-dnd";
import { DandActionsEnum, DandPrefixEnum, makeDroppableId } from "../../helpers/draganddrop";
import { Grid3D } from "../../shared/types/container";

export interface Containers3dProps {
  grid: Grid3D;
  dandAction: DandActionsEnum;
}

function Containers3d({ grid, dandAction }: Containers3dProps) {
  const { containers } = grid;
  const limit = grid.area?.limit ?? 5
  return (
    <div className={styles.containers}>
      <Droppable droppableId={makeDroppableId(DandActionsEnum.RELOCATING, DandPrefixEnum.C3D, grid.name)}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {Array(limit).fill(3).map((_, index) => (
              <C3D gridName={grid.name} container={containers[(limit - index) - 1]} zIndex={(limit - index) - 1} key={index} dandAction={dandAction} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

export default Containers3d;
