import React, { useState } from "react";
import styles from "./index.module.scss";
import { MainPropType } from "../../shared/types";
import { GridType } from "../../providers/ContainersProvider/typs";
import { useContainerState } from "../../providers/ContainersProvider/ContainersProvider";
import OperableGrid from "./components/OperableGrid";
import SelectableGrid from "./components/SelectableGrid";
import { MatrixGrid } from "../../shared/types/container";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { DandActionsEnum, DandPrefixEnum, makeDroppableId } from "../../helpers/draganddrop";

export interface GridProps {
  states: {
    currentGrid: MatrixGrid | null
    grid: MatrixGrid;
    dandAction: DandActionsEnum
    type?: "buffer";
  },
  actions: {
    setCurrentGrid: (matrixGrid: MatrixGrid) => void;
  }
}

function Grid({ states, actions }: GridProps) {
  const { grid, dandAction, type, currentGrid } = states
  const { setCurrentGrid } = actions
  // console.warn("GRIDDDD + " + grid.name)

  return (
    <>
      <Droppable droppableId={makeDroppableId(dandAction, DandPrefixEnum.GRID, grid.name)}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <OperableGrid isDraggingOver={snapshot.isDraggingOver} grid={grid} setCurrentGrid={setCurrentGrid} dandAction={dandAction} type={type} currentGrid={currentGrid} />
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </div>
        )}
      </Droppable>
    </>
  )
}

export default React.memo(Grid);
