import { Popover } from "antd";
import { GridType } from "../../../providers/ContainersProvider/typs";
import GridContainer from "./GridContainer";
import { useContainerActions, useContainerState } from "../../../providers/ContainersProvider/ContainersProvider";
import styles from "./grid.module.scss";
import clsx from "clsx";
import { colors } from "./constants";
import ContainerActions from "../../ContainerActions";
import InfoTable from "../../GridInfo/InfoTable";
import ContainerInfoTable from "../../GridInfo/ContainerInfoTable";
import { useQuery } from 'react-query'
import jwtAxios from '../../../auth/jwt-api'
import { MatrixGrid } from "../../../shared/types/container";
import { Draggable } from "react-beautiful-dnd";
import { useState } from "react";
import C3DSvgImage from "../../GridInfo/C3DSvgImage";
import { DandActionsEnum, DandPrefixEnum, makeDraggableId } from "../../../helpers/draganddrop";


export interface OperableGridProps {
    isDraggingOver?: boolean;
    setCurrentGrid: (matrixGrid: MatrixGrid) => void;
    grid: MatrixGrid;
    currentGrid: MatrixGrid | null;
    type?: "buffer";
    dandAction: DandActionsEnum
}

function OperableGrid({ grid, type, setCurrentGrid, isDraggingOver, dandAction, currentGrid }: OperableGridProps) {

    return (
        <div>
            <div>
                <Popover placement="left" content={
                    <div className={styles.popover}>
                        {/* {grid.name} */}
                        {/* {data && selectedContainerId && (selectedContainerId == data.data.data.id) &&
                            <div style={{ width: '250px' }}>
                                <ContainerInfoTable container={data.data?.data} />
                            </div>
                        } */}
                        <div>
                            {grid?.containers.sort((a, b) => b.z_axis - a.z_axis).map((container, index) => (
                                <div key={index}>
                                    <GridContainer {...{ container, grid, colors }} />
                                </div>
                            ))}
                        </div>
                        {/* {(currentGrid?.name == grid.name &&
                            <div>
                                <ContainerActions />
                            </div>)} */}
                    </div>
                } title={<div style={{ textAlign: 'center', fontSize: '20px' }}>{grid?.name}</div>} trigger="hover">
                    <div
                        onClick={() => {
                            if (!grid) return
                            setCurrentGrid(grid)
                            // if (!isSelected && !isMoving) {
                            //     // setSelectedGridName(grid.name)
                            // }
                        }}

                        className={clsx(
                            styles.container,
                            isDraggingOver && styles.isDraggingOver,
                            (currentGrid && currentGrid.name == grid.name) && styles.active,
                            // grid && isMoving && isFull && styles.disabled
                        )}
                        style={{
                            background:
                                type === "buffer"
                                    ? grid?.containers.length
                                        ? "var(--color-blue)"
                                        : "#cfcfcf"
                                    : grid?.containers.length
                                        ? `var(--color-${colors[grid.containers.length - 1]})`
                                        : "white"
                        }}
                    >
                        {
                            (grid.containers.length > 0)
                                ?
                                <Draggable
                                    key={grid.containers[0]?.id}
                                    draggableId={makeDraggableId(dandAction, DandPrefixEnum.GRID, String(grid.containers[0]?.number))} index={grid.id}>
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            {snapshot.isDragging
                                                ?
                                                <div>
                                                    <C3DSvgImage container={grid.containers[0]} width={100} height={40} />
                                                </div>
                                                :
                                                <div style={{ opacity: '0.6', userSelect: 'none' }}>
                                                    {grid.name}
                                                </div>
                                            }
                                        </div>
                                    )}
                                </Draggable>
                                :
                                <div style={{ opacity: '0.6', userSelect: 'none' }}>
                                    {grid.name}
                                </div>
                        }
                    </div>
                </Popover>
            </div>
            <div>

            </div>
        </div>
    )
}

export default OperableGrid