import React from "react";
import { MainPropType } from "../../../shared/types";
import styles from "./index.module.scss";
import trainimage from "../../../assets/images/trainimage.png";
import { GridType } from "../../../providers/ContainersProvider/typs";
import Grid from "../../Grid";
import { MatrixGrid } from "../../../shared/types/container";
import { DandActionsEnum } from "../../../helpers/draganddrop";



export interface TrainProps {
  actions: {
    setCurrentGrid: (matrixGrid: MatrixGrid) => void;
  },
  states: {
    currentGrid: MatrixGrid | null
    name: string;
    section: MatrixGrid[][];
    dandAction: DandActionsEnum
  }
}

function Train({ states, actions }: TrainProps) {
  const { name, section, dandAction, currentGrid } = states;
  const { setCurrentGrid } = actions;

  return (
    <div style={{ margin: "20px 0" }} className={styles.container}>
      <img src={trainimage} alt="" />
      <div className={styles["yard-b"]}>
        {section.map((column, i) => (
          <Grid key={i}
            states={{ grid: section[i][0], dandAction, currentGrid }}
            actions={{ setCurrentGrid }} />
        ))}
      </div>
      <div className={styles.yardname} style={{
        fontSize: '20px', padding: 5
      }}> {name}</div>
    </div>
  );
}

export default React.memo(Train);
