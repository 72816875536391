import React, { useRef, useState } from "react";
import styles from "./index.module.scss";
import { BiSearchAlt } from "react-icons/bi";
import { ContainerType } from "../../providers/ContainersProvider/typs";
import { Button, DatePicker, Input, Space, Table, TableProps } from "antd";
import clsx from "clsx";
import { useQuery } from "react-query";
import jwtAxios from "../../auth/jwt-api";
import { getColumns } from "../../pages/admin/Table/columns";
import { useContainerActions, useContainerState } from "../../providers/ContainersProvider/ContainersProvider";
import { CompanyType } from "../../shared/types/CompanyType";
import { ColumnType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { FilterConfirmProps } from "antd/es/table/interface";
import { getUrl } from "../../utils/getApi";
import Column from "antd/es/table/Column";
import { changeFields } from "../../utils/changeFields";
import moment from "moment";
import { Container3D } from "../../shared/types/container";

interface ContainerSearchProps {
  states: {
    search: string;
  },
  actions: {
    setSearch: (search: string) => void;
    setCurrentGridByContainer: (container: Container3D) => void;
  }
}

function ContainerSearch({ states, actions }: ContainerSearchProps) {
  const { search } = states;
  const { setSearch, setCurrentGridByContainer } = actions
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const timeRef = useRef<NodeJS.Timeout>();

  const [filters, setFilters] = useState<any>({});

  const api = getUrl(
    "master/container",
    {
      ...filters,
      status: "20", // COntainer status "POSITIONED"
      page: tableParams.pagination.current,
      rows: tableParams.pagination.pageSize, position: 1
      // only: "id,consignee,number,position,type_name,status_name,shipper,forwarder,invoice_number,bl_number,shipping_date,eta,ata",
    },
    search
  );

  //API REQUESTS

  //get containers
  const { data, isLoading } = useQuery([api], () => jwtAxios(api), {
    onSuccess(data) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data.data?.meta?.total,
        },
      });
      data?.data?.data.forEach((el: any, index: number) => {
        el.no =
          tableParams.pagination.current * tableParams.pagination.pageSize -
          tableParams.pagination.pageSize +
          index +
          1;
        el.key = el.id;
      });
    },
  });

  const { data: containerActions, isLoading: actionsLoading } = useQuery(['container-actions', search], () => {
    return jwtAxios.get(`master/container-actions`, {
      params: {
        container_number: search
      }
    })
  })

  const { data: containerType } = useQuery(
    ["list-container-types"],
    () => jwtAxios.get(`master/list-container-types`),
    {
      select: (res) => res?.data,
    }
  );

  const { data: containerStatus } = useQuery(
    ["list-container-status-types"],
    () => jwtAxios.get("/master/list-container-status-types"),
    {
      select: (res) => res?.data,
    }
  );

  const { data: containerInside } = useQuery(
    ["list-container-inside-types"],
    () => jwtAxios.get("/master/list-container-inside-types"),
    {
      select: (res) => res?.data,
    }
  );

  // Get list of company
  const { data: companies } = useQuery(
    ["company", "filter-companies"],
    () => jwtAxios.get("/master/company?only=name,id,type_id,type"),
    {
      select: (res) => res?.data?.data,
    }
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timeRef.current);
    timeRef.current = setTimeout(() => {
      setSearch(e.target.value);
    }, 1000);
  };

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: any[]) => {
      setCurrentGridByContainer(selectedRows[0]);
    },
  };

  const onChange: TableProps<any>["onChange"] = (
    pagination,
    changedeFilters,
    sorter: any,
    extra
  ) => {
    // console.log("changedeFilters", sorter, changedeFilters);
    let sort = sorter.columnKey;

    switch (sorter.order) {
      case "ascend":
        sort = sort;

        break;
      case "descend":
        sort = `-${sort}`;
        break;

      default:
        sort = "";
        break;
    }
    console.log({ changedeFilters });

    setFilters({ ...filters, ...changedeFilters, sort });
  };
  return (
    <div className={clsx(styles.container, "main-search-container")}>
      <form action="" onClick={(e) => e.preventDefault()}>
        <input
          type="text"
          placeholder="Search..."
          // value={search}
          onChange={handleSearch}
        />
        <button>
          <BiSearchAlt />
        </button>
      </form>

      <Table
        style={{ marginTop: 30 }}
        loading={isLoading}
        size="small"
        pagination={{
          ...tableParams.pagination,
          pageSize: tableParams.pagination.pageSize,
          onChange: (page, pageSize) => {
            setTableParams({
              ...tableParams,
              pagination: {
                ...tableParams.pagination,
                current: page,
                pageSize: pageSize,
              },
            });
          },
        }}
        rowSelection={{ type: "radio", ...rowSelection }}
        dataSource={data?.data?.data || []}
        columns={
          getColumns(undefined, {
            type_name: changeFields(containerType, {
              code: "value",
              name: "text",
            }),
            status_name: changeFields(containerStatus, {
              code: "value",
              name: "text",
            }),
            inside_name: changeFields(containerInside, {
              code: "value",
              name: "text",
            }),
            forwarder: changeFields(
              companies?.filter(({ type_id }: CompanyType) => type_id === 10),
              {
                id: "value",
                name: "text",
              }
            ),
            consignee: changeFields(
              companies?.filter(({ type_id }: CompanyType) => type_id === 30),
              {
                id: "value",
                name: "text",
              }
            ),
            shipper: changeFields(
              companies?.filter(({ type_id }: CompanyType) => type_id === 20),
              {
                id: "value",
                name: "text",
              }
            ),
          })["maincontainer"]
        }
        onChange={onChange}
      ></Table>


      <Table
        style={{ marginTop: 30 }}
        loading={isLoading}
        size="small"
        dataSource={containerActions?.data || []}
        columns={[
          {
            width: 70,
            align: 'center',
            title: "Raqami",
            dataIndex: "container_number",
            key: "number",
            render(data) {
              return <div style={{ padding: '10px' }}>{data}</div>
            }
          },
          {
            title: "Yuk holati",
            dataIndex: "container_inside",
            key: "number",
          },
          {
            title: "Faoliyat",
            dataIndex: "action",
            key: "number",
            align: 'center'
          },
          {
            title: "Hozirgi joy",
            dataIndex: "position",
            key: "number",
          },
          {
            title: "Eski joy",
            dataIndex: "old_position",
            key: "number",
          },
          {
            title: "Holati",
            dataIndex: "container_status",
            key: "number",
          },
          {
            title: "Turi",
            dataIndex: "container_type",
            key: "number",
          },
          {
            title: "Admin",
            align: 'center',
            dataIndex: "user_name",
            key: "number",
          },
          {
            title: "Forwarder",
            dataIndex: "forwarder_name",
            key: "number",
          },
          {
            title: "Shipper",
            dataIndex: "shipper_name",
            key: "number",
          },
          {
            title: "Consigne",
            dataIndex: "consignee_name",
            key: "number",
          },
          {
            title: "Sana va vaqt",
            dataIndex: "created_at",
            key: "number",
            align: 'center',
            render: (value: string) => <div><span style={{ fontWeight: 700 }}>{moment(value).format('HH:mm')}</span> {moment(value).format('MMM DD YYYY')}</div>
          },
        ]}
      ></Table>
    </div>
  );
}

export default ContainerSearch;
